/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { Link2 } from '../Link';
import { Button } from '..';
import Widget from './Widget';
import themes from './themes';

export default props => {
  const { title, description, ctaTitle, ctaLink, visualStyle } = props.content;
  const theme = themes?.[visualStyle] || themes['Bluelight'];
  return (
    <Widget title={title} bg={theme.bg} color={theme.color} titleColor={theme.titleColor} {...props}>
      {description && <Box>{description && description.description}</Box>}
      {ctaLink && ctaTitle && (
        <Flex sx={{ mt: 3 }}>
          <Button variant="smallSecondary" as={Link2} to={ctaLink}>
            {ctaTitle}
          </Button>
        </Flex>
      )}
    </Widget>
  );
};
