/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Map from './Map';
import Spinner from '../components/Spinner';
import ServicePointSearch from './ServicePointSearch';
import ServicePointList from './ServicePointList';
import ServicePointDetails from './ServicePointDetails';
import { getServices, getServicePoints, getServicePointsFromArea } from '../state/servicePoints';
import getLanguage from '../utils/getLanguage';
import { getServicePointById } from '../utils/api';
import ServicePointFilters from './ServicePointFilters';

const UI_WIDTH = 440;

export default function MapAndFilters({ officeId, serviceCodes, showP2, height = 80, limitOptionsOnMobile }) {
  const dispatch = useDispatch();
  const language = useSelector(state => getLanguage(state));
  const { servicePoints, servicePointSearchResults, services, language: loadedLanguage, fetching } = useSelector(
    state => state.servicePoints
  );
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [focusSelection, setFocusSelection] = useState(true);
  const [pointsFiltered, setPointsFiltered] = useState(servicePoints);
  const [pointsInView, setPointsInView] = useState(servicePoints);
  const [currentViewRect, setCurrentViewRect] = useState(null);

  useEffect(() => {
    if (services.length === 0 || language !== loadedLanguage) {
      dispatch(getServices(serviceCodes));
    }
  }, [dispatch, services.length, serviceCodes, language, loadedLanguage]);

  useEffect(() => {
    // Filter service points based on services
    let selectedServiceCodes = services.filter(s => s.selected).map(s => s.code);

    if (selectedServiceCodes.length > 0) {
      let pointsFiltered = servicePoints;
      const epIndex = selectedServiceCodes.indexOf('-EP');
      // Automated parcel terminals are filtered separately
      if (epIndex >= 0) {
        selectedServiceCodes.splice(epIndex, 1);
        pointsFiltered = pointsFiltered.filter(servicePoint => servicePoint.officeType !== 'EASYPACK');
      }
      const epIndex2 = selectedServiceCodes.indexOf('EP');
      if (epIndex2 >= 0) {
        selectedServiceCodes.splice(epIndex2, 1);
        pointsFiltered = pointsFiltered.filter(servicePoint => servicePoint.officeType === 'EASYPACK');
      }
      const p35Index = selectedServiceCodes.indexOf('P35');
      if (p35Index >= 0) {
        selectedServiceCodes.splice(p35Index, 1);
        pointsFiltered = pointsFiltered.filter(servicePoint =>
          (servicePoint.services || []).some(s => s.code === 'P4')
        );
      }

      // Regular service filtering
      if (selectedServiceCodes.length > 0) {
        pointsFiltered = pointsFiltered.filter(
          servicePoint =>
            servicePoint.services &&
            selectedServiceCodes.every(selectedService =>
              (servicePoint.services || []).some(
                pointService =>
                  pointService.code === selectedService || (pointService.code === 'P3' && selectedService === 'P2')
              )
            )
        );
      }
      setPointsFiltered(pointsFiltered);
    } else {
      setPointsFiltered(servicePoints);
    }
  }, [services, servicePoints]);

  useEffect(() => {
    // Filter out service points that are not on view
    if (currentViewRect) {
      const { left, right, top, bottom } = currentViewRect;
      const pointsInView = pointsFiltered.filter(office => {
        const { latitude, longitude } = office;
        return left < longitude && right > longitude && top > latitude && bottom < latitude;
      });
      setPointsInView(pointsInView);
    } else {
      setPointsInView(pointsFiltered);
    }
  }, [pointsFiltered, currentViewRect]);

  async function setServicePointAndFocus(servicePoint, focus) {
    setFocusSelection(focus);
    if (servicePoint) {
      const url = new URL(window.location);
      url.searchParams.set('id', servicePoint.officeCode);
      window.history.replaceState({}, '', url);
    }
    if (!servicePoint || language === 'fi') {
      setSelectedPoint(servicePoint);
    } else {
      const localizedSP = (await getServicePointById(servicePoint.officeCode, undefined, language))[0];
      setSelectedPoint(localizedSP);
    }
  }

  useEffect(() => {
    if (officeId) {
      (async () => {
        const sp = (await getServicePointById(officeId, undefined, language))[0];
        setSelectedPoint(sp);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPointsFromArea(viewRect) {
    setFocusSelection(false);
    dispatch(getServicePointsFromArea(viewRect));
  }

  const addressFound = useCallback(
    result => {
      dispatch(getServicePoints(result.PostalCode, result.Street, serviceCodes));
    },
    [dispatch, serviceCodes]
  );

  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box sx={{ position: 'relative' }}>
      <Map
        height={height}
        paddingLeft={UI_WIDTH}
        focus={focusSelection}
        offices={pointsFiltered}
        officesFocusGroup={servicePointSearchResults}
        selectedOffice={selectedPoint}
        onSelectOffice={servicePoint => setServicePointAndFocus(servicePoint, false)}
        onAddressFound={officeId ? null : addressFound}
        onViewChanged={setCurrentViewRect}
        onNewView={getPointsFromArea}
      />
      <Box
        sx={{
          position: ['relative', null, 'absolute'],
          width: ['auto', null, `${UI_WIDTH}px`],
          top: [0, null, 4],
          bottom: [null, null, 4],
          left: [0, null, 4],
        }}
      >
        <Flex
          sx={{
            flexDirection: 'column',
            p: 3,
            bg: 'white',
            boxShadow: ['none', 'dropdown'],
            borderRadius: [0, 1],
            overflowY: 'hidden',
            maxHeight: '100%',
          }}
        >
          <ServicePointSearch
            hideFilters={!!selectedPoint}
            setShowFilters={setShowFilters}
            onSearch={() => setServicePointAndFocus(null, true)}
          />

          {!selectedPoint && (
            <ServicePointFilters
              services={services}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              showP2={showP2}
            />
          )}
          {selectedPoint ? (
            <ServicePointDetails servicePoint={selectedPoint} onBack={() => setServicePointAndFocus(null, false)} />
          ) : fetching ? (
            <Box
              sx={{
                position: 'absolute',
                top: [24, null, 24],
                right: [28, null, 28],
                bg: 'grayLighter',
                p: 3,
                zIndex: 300,
              }}
            >
              <Spinner />
            </Box>
          ) : (
            <ServicePointList
              viewRect={currentViewRect}
              servicePoints={pointsInView}
              priorityPoints={servicePointSearchResults}
              onSelect={servicePoint => setServicePointAndFocus(servicePoint, true)}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
              limitOptionsOnMobile={limitOptionsOnMobile}
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
}
