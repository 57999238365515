/** @jsx jsx */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { jsx } from 'theme-ui';
import { useDispatch, useSelector } from 'react-redux';
import { changeLocale } from '../state/session';
import Button from './Button';

const LanguageLink = ({ locale, active, ...props }) => {
  return (
    <Button
      variant="plain"
      sx={{
        position: 'relative',
        fontWeight: active ? 'medium' : 'normal',
        px: 2,
        py: 0,
        color: ['white', null, null, 'hiContrast'],
        ':hover': {
          color: ['blueLighter', null, null, 'hiContrast'],
        },
        ':focus:not(:active)': {
          boxShadow: 'inputFocus',
        },
        '::after': {
          content: '""',
          position: 'absolute',
          right: 0,
          top: 0,
          bottom: 0,
          width: 1,
          bg: 'border',
        },
        ':last-of-type::after': {
          display: 'none',
        },
      }}
      {...props}
    >
      {locale.toUpperCase()}
    </Button>
  );
};

const LanguageSelector = ({ paths, hideLocales = [], ...props }) => {
  const dispatch = useDispatch();
  const container = useRef();
  const [open, setOpen] = React.useState(false);
  const loc = (useSelector(state => state.session.locale) || 'fi').toUpperCase();

  const handleClickOutside = e => {
    if (container.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
  };

  const handleChange = locale => {
    setLocale(locale);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const setLocale = locale => {
    dispatch(changeLocale(locale, paths));
  };

  const languages = useMemo(() => {
    return ['fi', 'sv', 'en'].filter(l => !hideLocales.includes(l));
  }, [hideLocales]);

  const langNames = {
    fi: 'suomi',
    sv: 'svenska',
    en: 'english',
  };
  const [focusIndex, setFocusIndex] = useState(languages.findIndex(l => l.toUpperCase() === loc) || 0);
  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.language'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : languages.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < languages.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const language = languages[i];
            if (language) {
              setImmediate(() => {
                handleChange(language);
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [languages, setFocusIndex, handleChange]
  );

  return (
    <div
      ref={container}
      sx={{ position: 'relative' }}
      role="group"
      aria-label="valitse kieli"
      onKeyDown={onKeyDown}
      {...props}
    >
      {languages.map((lang, index) => (
        <LanguageLink
          key={lang}
          locale={lang}
          active={loc.toLowerCase() === lang}
          onClick={e => {
            handleChange(lang);
          }}
          className="language"
          role="button"
          aria-label={langNames[lang]}
          tabIndex={focusIndex === index ? 0 : -1}
        />
      ))}
    </div>
  );
};

export default LanguageSelector;
