import get from 'lodash/get';
import { locNavigate } from './session';
import { getTrackingInfo } from '../utils/api';
import { showNotification, SEVERITY } from './notifications';
import getLanguage from '../utils/getLanguage';

const initialState = {
  fetching: false,
  error: null,
  parcel: null,
  parcelNumber: null,
  language: null,
};

const TRACKING_FETCH_BEGIN = 'TRACKING_FETCH_BEGIN';
const TRACKING_FETCH_SUCCESS = 'TRACKING_FETCH_SUCCESS';
const TRACKING_FETCH_ERROR = 'TRACKING_FETCH_ERROR';

export const findTrackingInfo = parcelNumber => async (dispatch, getState) => {
  const language = getLanguage(getState());
  dispatch(findTrackingInfoBegin(parcelNumber, language));

  const response = await getTrackingInfo(parcelNumber, language);
  if (getState().tracking.parcelNumber !== parcelNumber) {
    return;
  }
  if (response.err) {
    dispatch({ type: TRACKING_FETCH_ERROR });
    const status = get(response.err, 'response.status');
    if (status === 400) {
      return dispatch(showNotification('tracking.notFound', SEVERITY.WARN));
    } else {
      return dispatch(showNotification('tracking.searchFailed'));
    }
  }

  if (!response.trackingEvents || response.trackingEvents.length === 0) {
    dispatch({ type: TRACKING_FETCH_ERROR });
    dispatch(locNavigate('/tracking', '?parcelNumber=' + parcelNumber));
    return dispatch(showNotification('tracking.notFound', SEVERITY.WARN));
  }

  dispatch(findTrackingInfoSuccess(parcelNumber, response, language));
  if (response.detailsForLoggedInUser) {
    const { direction } = response.detailsForLoggedInUser;
    return dispatch(
      locNavigate(direction === 'sent' ? '/my-pages/sent-parcels' : '/my-pages/incoming-parcels', '', {
        state: { shipment: response.detailsForLoggedInUser },
      })
    );
  } else {
    return dispatch(locNavigate('/tracking', '?parcelNumber=' + parcelNumber));
  }
};

export const findTrackingInfoForClearance = (parcelNumber, navigateToPage = true) => async (dispatch, getState) => {
  dispatch(findTrackingInfoBegin(parcelNumber));
  const language = getLanguage(getState());
  const response = await getTrackingInfo(parcelNumber, language);
  if (getState().tracking.parcelNumber !== parcelNumber) {
    return;
  }
  if (response.err) {
    dispatch({ type: TRACKING_FETCH_ERROR });
    const status = get(response.err, 'response.status');
    if (status === 400) {
      return dispatch(showNotification('tracking.notFound', SEVERITY.WARN));
    } else {
      return dispatch(showNotification('tracking.searchFailed'));
    }
  }

  if (!response.trackingEvents || response.trackingEvents.length === 0) {
    dispatch({ type: TRACKING_FETCH_ERROR });
    dispatch(locNavigate('/tracking', '?parcelNumber=' + parcelNumber));
    return dispatch(showNotification('tracking.notFound', SEVERITY.WARN));
  }
  dispatch(findTrackingInfoSuccess(parcelNumber, response));

  if (!navigateToPage) {
    return;
  } else {
    return dispatch(locNavigate('/tullaus', '?parcelNumber=' + parcelNumber));
  }
};

const findTrackingInfoBegin = (parcelNumber, language) => ({
  type: TRACKING_FETCH_BEGIN,
  parcel: null,
  parcelNumber,
  language,
});

const findTrackingInfoSuccess = (parcelNumber, parcel, language) => ({
  type: TRACKING_FETCH_SUCCESS,
  parcel,
  language,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case TRACKING_FETCH_BEGIN:
      return {
        ...state,
        fetching: true,
        parcel: null,
        parcelNumber: action.parcelNumber,
        language: action.language,
      };
    case TRACKING_FETCH_SUCCESS:
      return { ...state, fetching: false, parcel: action.parcel, language: action.language };
    case TRACKING_FETCH_ERROR:
      return { ...state, fetching: false };
    default:
      return state;
  }
};
