/** @jsx jsx */
import React from 'react';
import { jsx, Box, Flex, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Button from '../components/Button';
import Link, { Link2 } from '../components/Link';
import ServiceDetails from './ServiceDetails';
import ServicePointContainer from './ServicePointContainer';
import { capitalize } from '../utils/string';

const showSeparateTravelerOffices = [
  7, // Helsinki
  59, // Tampere
];

const ServiceSection = ({ services }) => {
  const order = [
    'M1',
    'M2',
    'M3',
    'WC',
    'WI',
    'MT',
    'OT',
    'KK',
    'ES',
    'P1',
    'P2',
    'EXP',
    'P3',
    'P4',
    'D1',
    'SL',
    //'WU',
  ];
  services.sort((a, b) => order.indexOf(a.code) - order.indexOf(b.code));
  if (services.length === 0) {
    return null;
  }

  const serviceCodesWithIcon = [
    'M1',
    'M2',
    'M3',
    'WC',
    'WI',
    'MT',
    'OT',
    'KK',
    'ES',
    'P1',
    'P2',
    'P3',
    'P4',
    'D1',
    'SL',
    //'WU',
    'EXP',
  ];
  return (
    <Flex sx={{ mb: 3, flexWrap: 'wrap', '> span': { mr: 2 } }}>
      {services
        .filter(service => service.code !== 'WU')
        .map(service => (
          <Flex
            key={service.code}
            sx={{
              mr: 1,
              mb: 1,
              py: 2,
              px: 2,
              justifyContent: 'center',
              alignItems: 'center',
              bg: 'blueLighter',
              borderRadius: 1,
              fontSize: 1,
            }}
          >
            {serviceCodesWithIcon.includes(service.code) ? (
              <Flex
                sx={{
                  width: 20,
                  height: 20,
                  mr: 2,
                }}
              >
                <img src={`/services/${service.code}.svg`} alt={service.description} />
              </Flex>
            ) : null}
            {service.description.toLowerCase()}
          </Flex>
        ))}
    </Flex>
  );
};

const ServiceIcons = ({ services }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const travelServiceCodes = ['M1', 'M2', 'M3', 'WC', 'WI', 'MT', 'OT', 'KK', 'ES'];
  const excludeServices = ['SL'];
  const parcelServiceCodes = ['P1', 'P2', 'P3', 'P4', 'D1', 'SL'];

  const travelServices = [];
  const parcelServices = [];
  const otherServices = [];

  (services || []).forEach(service => {
    if (excludeServices.includes(service.code)) {
      // no-op
    } else if (travelServiceCodes.includes(service.code)) {
      travelServices.push(service);
    } else if (parcelServiceCodes.includes(service.code)) {
      if (service.code === 'P3' && !excludeServices.includes('P4')) {
        // if P4 is shown, hide P3
        const p4 = services.find(s => s.code === 'P4');
        if (p4) return;
      }
      if (service.code === 'P3') {
        parcelServices.push({ ...service, description: translate('servicePoints.parcelServices.P3') });
      } else if (service.code === 'P4') {
        parcelServices.push({ ...service, description: translate('servicePoints.parcelServices.P4') });
      } else {
        parcelServices.push(service);
      }
    } else {
      otherServices.push(service);
    }
  });

  if (otherServices.length > 0) {
    if (travelServices.length > 0) {
      travelServices.push(...otherServices);
    } else {
      parcelServices.push(...otherServices);
    }
  }

  return (
    <>
      <ServiceSection services={travelServices} />
      <ServiceSection services={parcelServices} />
    </>
  );
};

const BuyButtons = () => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <Flex sx={{ color: 'white', mt: 3 }}>
      <Button as={Link} to="/buy-parcel" sx={{ mr: 3, flex: 1 }}>
        {translate('servicePoints.buyParcel')}
      </Button>
      <Button as={Link} to="https://liput.matkahuolto.fi/" sx={{ flex: 1 }}>
        {translate('servicePoints.buyTicket')}
      </Button>
    </Flex>
  );
};
function ServicePointDetails({ servicePoint, onBack }) {
  // console.log('sppp', servicePoint);
  const {
    officeCode,
    travelservices: [travelServices = {}] = [],
    parcelservices: [parcelServices = {}] = [],
    services,
  } = servicePoint;
  const translate = getTranslate(useSelector(state => state.localize));
  const separateTravelInfo = showSeparateTravelerOffices.includes(+officeCode);

  console.log(servicePoint);

  return (
    <ServicePointContainer>
      <Button variant="plain" onClick={onBack}>
        {translate('backButton')}
      </Button>
      <Styled.h3 sx={{ mt: 0, mb: 1 }}>{servicePoint.officeName}</Styled.h3>
      <Box>
        {capitalize(servicePoint.officeStreetAddress)}, {servicePoint.officePostalCode}{' '}
        {capitalize(servicePoint.officeCity)}
      </Box>
      {separateTravelInfo && (
        <ServiceDetails
          type={'Matkapalvelut'}
          showTitle={true}
          officeType={servicePoint.officeType}
          {...travelServices}
        />
      )}
      <ServiceDetails
        type={'Pakettipalvelut'}
        showTitle={separateTravelInfo}
        officeType={servicePoint.officeType}
        {...(separateTravelInfo ? {} : travelServices)}
        {...parcelServices}
      />
      <ServiceIcons services={services} />
      {true && (
        <Flex sx={{ mt: 3, flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <Button as={Link2} variant="plain" sx={{ py: 0 }} to="/asiakaspalvelu">
            {translate('servicePoints.customerService')}
          </Button>
          {servicePoint?.officeChain === 'IBOXEN' ? (
            <Button
              as={Link2}
              variant="plain"
              sx={{ py: 0 }}
              to="/palvelupisteverkosto/sovelluksella-toimivat-ulkoautomaatit"
            >
              {translate('servicePoints.readIBoxenInstructions')}
            </Button>
          ) : null}
        </Flex>
      )}
      <BuyButtons />
    </ServicePointContainer>
  );
}

ServicePointDetails.propTypes = {
  servicePoint: PropTypes.object,
  onBack: PropTypes.func,
};

export default ServicePointDetails;
