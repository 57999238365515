/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export default props => (
  <Box
    sx={{
      overflowY: [null, null, 'auto'],
    }}
    children={props.children}
  />
);
