/** @jsx jsx */
import { jsx, Flex } from 'theme-ui';
import { Button } from '../';
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { buyParcel, buySeriesShipment } from '../../state/parcelFlow';
import { getTranslate } from 'react-localize-redux';
import { imagePathForProduct, TIRESET_PRODUCT_ID } from '../../state/catalog';
import { formatPrice } from '../../utils/formatters';
import { getLocalizedCountryName } from '../../utils/countryCode';

export default ({ name: productName, price, maxSize, productId, countryCode, productType }) => {
  const isSeriesProduct = productType === 'Sarjapaketti';
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    !isSeriesProduct
      ? dispatch(buyParcel(productId, countryCode || 'FI'))
      : dispatch(buySeriesShipment(true, productId, countryCode || 'FI'));
  }, [dispatch, productId, countryCode, isSeriesProduct]);
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: 160,
        maxWidth: 180,
        overflow: 'hidden',
        position: 'relative',
        scrollSnapAlign: 'start',
        mr: 2,
      }}
    >
      <Flex
        onClick={onClick}
        role="button"
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 2,
          border: '2px solid',
          borderColor: 'border',
          px: 3,
          pt: 0,
          pb: 3,
          height: '100%',
          cursor: 'pointer',
          ':hover': {
            borderColor: 'grayLight',
          },
        }}
      >
        {countryCode && (
          <img
            src={`/flags/${countryCode.toLowerCase()}.svg`}
            alt={getLocalizedCountryName(countryCode, locale)}
            sx={{
              position: 'absolute',
              top: 2,
              right: 2,
              width: 24,
              height: 24,
            }}
          />
        )}
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            width: 96,
            height: 96,
            flex: 'none',
          }}
        >
          <img src={imagePathForProduct(productId)} alt={productName} width="96" height="96" />
        </Flex>
        <Flex
          sx={{
            fontSize: 18,
            fontWeight: 'medium',
            mb: 2,
            width: '100%',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isSeriesProduct ? (
            <>
              {translate('buyParcel.product.seriesShipment')}
              <br />
              {productName}
            </>
          ) : (
            productName
          )}
        </Flex>
        <div sx={{ fontSize: 1, lineHeight: 'normal', textAlign: 'center', color: 'black' }}>
          {productId === '11Polkupyörä35' ? (
            <span
              dangerouslySetInnerHTML={{
                __html: translate('buyParcel.product.bikeSize'),
              }}
            />
          ) : (
            <>
              {translate('buyParcel.product.maxSize')}
              <br />
              {maxSize}
            </>
          )}
        </div>
        <div sx={{ mt: 3 }}>
          <Button variant="small" onClick={onClick}>
            {formatPrice(price)}
          </Button>
        </div>
      </Flex>
    </Flex>
  );
};
