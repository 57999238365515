import React from 'react';
import { default as ReduxWrapper } from './src/state/ReduxWrapper';
import LocalizationWrapper from './src/localization/LocalizationWrapper';
import SessionCheck from './src/components/SessionCheck';
import { ReturnShipmentProvider } from './src/pages/return-shipment';
import { Provider as UpgradeProvider } from './src/upgrade';

export const wrapRootElement = ({ element }) => (
  <ReduxWrapper>
    <ReturnShipmentProvider>
      <UpgradeProvider>
        <LocalizationWrapper>
          <SessionCheck>{element}</SessionCheck>
        </LocalizationWrapper>
      </UpgradeProvider>
    </ReturnShipmentProvider>
  </ReduxWrapper>
);

export const onPrefetchPathname = ({ pathname, loadPage }) => {
  loadPage(pathname);
};

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});
