/** @jsx jsx */
import { jsx, Flex, Box, Styled } from 'theme-ui';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { withFormik, Form } from 'formik';
import moment from 'moment';
import * as yup from 'yup';
import AccountLayout from '../account/AccountLayout';
import { Button } from '../components';
import AutocompleteStreetField from '../components/AutocompleteStreetField';
import Container from '../components/Container';
import FormField from '../components/FormField';
import Link from '../components/Link';
import { PaymentSelection } from '../components/Payment';
import PostalCodeAndCityFields from '../components/PostalCodeAndCityFields';
import RichText from '../components/RichText';
import Spinner from '../components/Spinner';
import Switch from '../components/Switch';
import { SendParcelWidget, TrackingSearchWidget } from '../components/widgets';
import FullHeightColumn from '../components/FullHeightColumn';
import { showNotification } from '../state/notifications';
import { locNavigate, getCards } from '../state/session';
import AddDiscountCode from '../storage/AddDiscountCode';
import { isBrowser } from '../utils';
import * as api from '../utils/api';
import { formatPrice } from '../utils/formatters';
import getLanguage, { useTranslate } from '../utils/getLanguage';
import { postalCodeRegExp } from '../utils/regExp';
import * as streetAndApartment from '../utils/streetAndApartment';
import { setOrderId } from '../utils/order';
import { setPickup } from '../state/basket';
import * as analytics from '../utils/analytics';

const STEP = {
  NOT_SET: 0,
  DETAILS: 1,
  PAYMENT: 2,
  DONE: 3,
};

const noContentPlaceholder = {
  content: [
    {
      content: [{ nodeType: 'text', marks: [], value: 'Sisältö puuttuu.' }],
      nodeType: 'paragraph',
    },
  ],
  nodeType: 'document',
};

const getRichtext = (data, slug, locale) => {
  const edges = data.allContentfulLayoutRichText.edges || [];
  const node =
    edges.find(e => e.node.slug === slug && e.node.node_locale.startsWith(locale)) ||
    edges.find(e => e.node.slug === slug && e.node.node_locale.startsWith('fi'));
  return (
    (node && node.node.body) || {
      raw: JSON.stringify(noContentPlaceholder),
    }
  );
};

const hyperlinkHack = (uri, linkText) => {
  // this probably doesnt work because order of nodeType, value, marks, data is not fixed
  const jsonFragment = `",
  "data": {},
  "marks": []
},
{
  "nodeType": "hyperlink",
  "content": [
    {
      "nodeType": "text",
      "value": "${linkText}",
      "marks": [],
      "data": {}
    }
  ],
  "data": {
    "uri": "${uri}"
  }
},
{
  "nodeType": "text",
  "value": "
`.replace(/\n/g, '');
  return {
    hack: true,
    json: jsonFragment,
    uri,
    linkText,
  };
};

const replaceVariables = (richtext, vars) => {
  let raw = richtext.raw;
  Object.keys(vars).forEach(key => {
    let value = vars[key];
    if (value.hack) {
      value = value.json;
    } else {
      value = ('' + value).replace(/"/g, '\\"');
    }
    raw = raw.replace(`\${${key}}`, value);
  });
  return { ...richtext, raw };
};

const PickupForm = ({ values: { hasPickup, discount }, setFieldValue, handleSubmit, isSubmitting }) => {
  const { pickupPrice } = useContext(ReturnContext);
  const translate = getTranslate(useSelector(state => state.localize));
  const changePickup = useCallback(() => {
    setFieldValue('hasPickup', !hasPickup);
  }, [setFieldValue, hasPickup]);

  const addDiscountVisible = !discount;
  const servicePrice = (pickupPrice && pickupPrice.price) || 0;
  const discountAmount =
    (discount &&
      (discount.amount
        ? Math.min(servicePrice, discount.amount)
        : +((servicePrice * discount.percentage) / 100).toFixed(2))) ||
    0;
  const discountedPrice = servicePrice - discountAmount;
  const requiresPayment = hasPickup && +discountedPrice > 0;
  const pickupDisabled = false; // ['production'].includes(process.env.GATSBY_ACTIVE_ENV);

  return (
    <Form
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <Box sx={{ my: pickupDisabled ? 0 : 4, maxWidth: 640 }}>
        {!pickupDisabled && pickupPrice && (
          <Box sx={{ mb: 2 }}>
            <Switch checked={hasPickup} onChange={changePickup}>
              <span sx={{ fontWeight: 'medium' }}>
                {translate('returnShipment.pickupButton', { price: formatPrice(pickupPrice.price) })}
              </span>
            </Switch>
          </Box>
        )}
        {hasPickup && (
          <>
            <Box sx={{ bg: 'blueLighter', my: 3, p: 3, borderRadius: 1 }}>
              {translate('basket.pickup.note1')}
              <br />
              {translate('basket.pickup.note2')}
            </Box>
            <div sx={{ fontWeight: 'medium', mb: 2 }}>{translate('buyParcel.sender.addrTitle')}</div>
            <div style={{ display: 'flex' }}>
              <div sx={{ flexGrow: 1, mr: 3, zIndex: 3 }}>
                <AutocompleteStreetField label={translate('address.street')} country="FIorAX" />
              </div>
              <div sx={{ minWidth: '25%' }}>
                <FormField name="apartment" label={translate('address.apartment')} />
              </div>
            </div>
            <PostalCodeAndCityFields country="FIorAX" />
            <FormField name="remarks" label={translate('buyParcel.sender.remarks')} />

            {addDiscountVisible && <AddDiscountCode serviceCode="NO" />}
            {discount && (
              <div>
                {translate('discount.code')} <span sx={{ fontWeight: 'medium' }}>{discount.code}</span>{' '}
                {formatPrice(-discountAmount)}
              </div>
            )}

            <p sx={{ color: 'primary', fontSize: 4, fontWeight: 'medium' }}>
              {translate('delivery.time.price')}: {formatPrice(discountedPrice)}
            </p>
          </>
        )}
        <Flex
          sx={{
            justifyContent: ['flex-end', null, 'flex-start'],
            mt: 4,
          }}
        >
          <Button type="submit" onClick={handleSubmit} disabled={isSubmitting}>
            {!requiresPayment ? translate('returnShipment.activate') : translate('returnShipment.activateAndPay')}
          </Button>
        </Flex>
      </Box>
    </Form>
  );
};

const PickupFormik = withFormik({
  mapPropsToValues: ({ sender }) => {
    return {
      hasPickup: sender?.hasPickup || false,
      street: sender.street || '',
      apartment: sender.apartment || '',
      postcode: sender.postcode || '',
      city: sender.city || '',
      remarks: sender.remarks || '',
      discount: sender.discount || null,
    };
  },

  validationSchema: ({ translate }) => {
    return yup.object().shape({
      street: yup.string().when('hasPickup', {
        is: true,
        then: yup.string().required(translate('form.requiredField')),
      }),
      apartment: yup.string(),
      postcode: yup.string().when('hasPickup', {
        is: true,
        then: yup
          .string()
          .required(translate('form.requiredField'))
          .matches(postalCodeRegExp, translate('form.invalidPostcode')),
      }),
      city: yup.string().when('hasPickup', {
        is: true,
        then: yup.string().required(translate('form.requiredField')),
      }),
      remarks: yup.string(),
      discount: yup
        .object({
          code: yup.string(),
          amount: yup.string(),
        })
        .nullable(),
    });
  },

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },

  displayName: 'PickupForm',
})(PickupForm);

const mapProfile2Address = profile => {
  const [street, apartment] = streetAndApartment.split(profile.streetAddress);
  const postcode = profile.postNumber;
  const city = profile.postOffice;
  return {
    street,
    apartment,
    postcode,
    city,
  };
};

const Pickup = () => {
  const { sender, setSender, goPayment, createOrder } = useContext(ReturnContext);
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async values => {
      if (!values.hasPickup) {
        setSender({ ...sender, hasPickup: false });
        await createOrder({ paymentMethod: 'FREE' });
      } else {
        setSender(values);
        // todo discount full price
        goPayment();
      }
    },
    [sender, setSender, goPayment, createOrder]
  );

  const user = useSelector(state => state.session.user);
  useEffect(() => {
    if (user && !user.cards) {
      dispatch(getCards());
    }
  }, [user, dispatch]);

  const formProps = { sender, onSubmit, translate };

  return <PickupFormik {...formProps} />;
};

const ReturnValid = ({ body }) => {
  const {
    goPayment,
    shipment: { shipmentNumber, senderName },
  } = useContext(ReturnContext);
  const locale = useSelector(state => state.session.locale);
  const translate = getTranslate(useSelector(state => state.localize));
  const onBackClick = useCallback(() => window.history.back(), []);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.title')}</Styled.h1>
      <RichText content={replaceVariables(body, { shipmentNumber, sender: senderName })} locale={locale} />
      <Pickup goPayment={goPayment} />
    </FullHeightColumn>
  );
};

const ReturnTooLate = ({ body }) => {
  const locale = useSelector(state => state.session.locale);
  const translate = getTranslate(useSelector(state => state.localize));
  const onBackClick = useCallback(() => window.history.back(), []);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.title')}</Styled.h1>
      <RichText content={body} locale={locale} />
    </FullHeightColumn>
  );
};

const ReturnCodeForm = ({ setFieldValue, isSubmitting, translate }) => {
  const [showButton, setShowButton] = useState(false);
  const onInputChange = useCallback(
    e => {
      const { value } = e.target;
      setFieldValue('code', value.toUpperCase().replace(/[^A-Z0-9]/g, '')); // remove non-alpha numeric characters
      if (value) setShowButton(true);
      else setShowButton(false);
    },
    [setFieldValue]
  );

  return (
    <Form
      sx={{
        display: 'flex',
        mt: 4,
        maxWidth: ['100%', 360],
      }}
    >
      <Box sx={{ width: '100%', flex: 1 }}>
        <FormField name="code" onChange={onInputChange} label={translate('returnShipment.enterCode')} />
      </Box>
      {showButton && (
        <Button sx={{ alignSelf: 'flex-start', mt: 24, ml: 2 }} type="submit">
          {isSubmitting ? (
            <Box sx={{ position: 'relative' }}>
              <Spinner color="white" />
              <span sx={{ visibility: 'hidden', opacity: 0 }} aria-hidden="true">
                {translate('returnShipment.checkCode')}
              </span>
            </Box>
          ) : (
            translate('returnShipment.checkCode')
          )}
        </Button>
      )}
    </Form>
  );
};

const ReturnCodeFormik = withFormik({
  mapPropsToValues: ({ initialValue }) => ({
    code: initialValue || '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      code: yup.string().min(3, translate('discount.tooShort')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'ReturnCodeForm',
})(ReturnCodeForm);

const ReturnCode = ({ initialValue }) => {
  const { checkCode } = useContext(ReturnContext);
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);

  const onSubmit = useCallback(
    async (values, { setFieldError, setSubmitting }) => {
      const errorCode = await checkCode(values.code);
      if (errorCode) {
        setFieldError('code', translate('returnShipment.invalidCode'));
      }
    },
    [checkCode, translate]
  );

  const formProps = { onSubmit, translate, initialValue };
  return <ReturnCodeFormik {...formProps} />;
};

const ReturnWithCode = ({ body, validCode }) => {
  const {
    shipment: { shipmentNumber, senderName },
    goPayment,
    returnCode,
  } = useContext(ReturnContext);
  const locale = useSelector(state => state.session.locale);
  const translate = getTranslate(useSelector(state => state.localize));
  const onBackClick = useCallback(() => window.history.back(), []);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.title')}</Styled.h1>
      <RichText content={replaceVariables(body, { shipmentNumber, sender: senderName })} locale={locale} />
      <ReturnCode initialValue={returnCode} />
      {!!returnCode && (
        <>
          <RichText content={validCode} locale={locale} />
          <Pickup goPayment={goPayment} />
        </>
      )}
    </FullHeightColumn>
  );
};

const ReturnOther = ({ body }) => {
  const locale = useSelector(state => state.session.locale);
  const translate = getTranslate(useSelector(state => state.localize));
  const onBackClick = useCallback(() => window.history.back(), []);

  return (
    <FullHeightColumn>
      <Box>
        <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
          {translate('backButton')}
        </Button>
      </Box>

      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.title')}</Styled.h1>
      <RichText content={body} locale={locale} />
    </FullHeightColumn>
  );
};

const PaymentStep = () => {
  const { goBack, createOrder } = useContext(ReturnContext);
  const [isProcessing, setProcessing] = useState(false);

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      await createOrder(paymentOptions);
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />;
};

const ReadyStep = ({ body, bodyAlt }) => {
  const locale = useSelector(state => state.session.locale);
  const { order } = useContext(ReturnContext);
  const translate = useTranslate();
  const { activationCode, pickup } = order;
  const { shipmentNumber: originalShipmentNumber, recipient } = order.return;
  const { street, apartment, postcode, city } = order.sender;
  const params = {
    shipmentNumber: originalShipmentNumber, // hyperlinkHack('seuranta?parcelNumber=' + originalShipmentNumber, originalShipmentNumber),
    sender: recipient.name,
    activationCode,
    senderAddress: `${street.trim()} ${apartment?.trim() || ''}, ${postcode} ${city}`,
  };

  return (
    <FullHeightColumn>
      <div
        sx={{
          '& u': {
            // activation code has underline in contentful
            textDecoration: 'none',
            fontWeight: 'medium',
            fontSize: 3,
            color: 'primary',
            ml: 2,
          },
        }}
      >
        {pickup ? (
          <RichText content={replaceVariables(bodyAlt, params)} locale={locale} />
        ) : (
          <>
            <RichText content={replaceVariables(body, params)} locale={locale} />
            <Button
              as={Link}
              to={'/palvelupisteet'}
              title={translate('returnShipment.findServicePoints')}
              variant="plain"
            >
              {translate('returnShipment.findServicePoints')}
            </Button>
          </>
        )}
      </div>
    </FullHeightColumn>
  );
};

export const ReturnContext = React.createContext({
  shipment: {},
  order: {},
  step: STEP.NOT_SET,
  setState: () => {},
  setOrderPayed: () => {},
  goBack: () => {},
  goPayment: () => {},
  sender: {},
  setSender: () => {},
  returnCode: '',
  checkCode: () => {},
  createOrder: () => {},
  pickupPrice: null,
});

export const ReturnShipmentProvider = ({ children }) => {
  const [shipment, setShipment2] = useState(null);
  const [order, setOrder] = useState(null);
  const [step, setStep] = useState(STEP.NOT_SET);
  const dispatch = useDispatch();
  const goBack = useCallback(() => {
    if (step === STEP.DETAILS) {
      window.history.back();
    } else {
      setStep(s => s - 1);
    }
  }, [step, setStep]);
  const goPayment = useCallback(() => {
    setStep(STEP.PAYMENT);
  }, [setStep]);

  const [sender, setSender] = useState(null);
  const setShipment = useCallback(
    shipment => {
      setShipment2(shipment);
      const user = dispatch((dispatch, getState) => getState().session.user);
      if (user) {
        setSender({
          ...mapProfile2Address(user),
          hasPickup: false,
        });
      }
      setStep(STEP.DETAILS);
    },
    [dispatch]
  );

  const setOrderPayed = useCallback(order => {
    setOrder(order);
    setStep(STEP.DONE);
  }, []);

  const [returnCode, setReturnCode] = useState('');
  const checkCode = useCallback(
    async code => {
      try {
        const response = await api.checkReturnCode(shipment.shipmentNumber, code);
        if (response.valid) {
          setReturnCode(code);
          return;
        } else {
          return 'INVALID_CODE';
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [shipment, setReturnCode, dispatch]
  );

  const [pickupPrice, setPickupPrice] = useState(null);
  useEffect(() => {
    (async () => {
      if (!shipment) {
        return;
      }
      const response = await api.getReturnPickupPrice(shipment.shipmentNumber);
      if (response.price) {
        setPickupPrice(response);
      }
    })();
  }, [setPickupPrice, shipment]);

  const createOrder = useCallback(
    async paymentOptions => {
      try {
        const { hasPickup, discount, ...senderOther } = sender;
        const language = dispatch((dispatch, getState) => getLanguage(getState()));
        const response = await api.createReturnShipmentOrder(
          shipment.shipmentNumber,
          paymentOptions,
          language,
          returnCode,
          hasPickup ? senderOther : null,
          discount ? discount.code : null
        );
        const { redirectUrl, orderId, transactionId } = response;
        if (redirectUrl) {
          setOrderId(orderId, transactionId);
          isBrowser && window.location.assign(redirectUrl);
          return;
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    },
    [returnCode, shipment, sender, dispatch]
  );

  return (
    <ReturnContext.Provider
      value={{
        shipment,
        order,
        step,
        setShipment,
        setOrderPayed,
        goBack,
        goPayment,
        sender,
        setSender,
        returnCode,
        checkCode,
        createOrder,
        pickupPrice,
      }}
    >
      {children}
    </ReturnContext.Provider>
  );
};

export default ({ location: { state = {} } = {}, pageContext, data }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const { step, shipment, setShipment, setOrderPayed } = useContext(ReturnContext);

  const stateKeys = Object.keys(state || {});
  const hasState = stateKeys.includes('shipment') || stateKeys.includes('order');

  if (!hasState) {
    try {
      state = isBrowser && JSON.parse(window.sessionStorage.getItem('returnShipment'));
    } catch (err) {
      // no-op
    }
  } else if (!state.saved && (state.shipment || state.order)) {
    // remember shipment if user refresh browser
    isBrowser && window.sessionStorage.setItem('returnShipment', JSON.stringify(state));
    state.saved = true;
  }

  useEffect(() => {
    if (step === STEP.NOT_SET) {
      if (state.shipment) {
        setShipment(state.shipment);
      } else if (state.order) {
        setOrderPayed(state.order);
      } else {
        console.log('Invalid state: no return shipment');
      }
    }
  }, []);

  const { customerReturnUntil, returnPermissionCode } = shipment || {}; // from mpaketti

  const now = moment();
  const returnUntil = customerReturnUntil && moment(customerReturnUntil); // .add(1, 'day');
  const returnExpired = returnUntil && now >= returnUntil;

  return (
    <AccountLayout
      title={translate('returnShipment.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      sidebar={
        step === STEP.PAYMENT ? null : (
          <Box>
            <SendParcelWidget sidebar sx={{ mb: 3 }} />
            <TrackingSearchWidget sidebar />
          </Box>
        )
      }
    >
      <Container>
        {step === STEP.DETAILS ? (
          returnUntil && !returnExpired ? (
            <ReturnValid body={getRichtext(data, 'return-no-code', locale)} />
          ) : !!returnPermissionCode ? (
            <ReturnWithCode
              body={getRichtext(data, 'return-code', locale)}
              validCode={getRichtext(data, 'return-code-valid', locale)}
            />
          ) : returnUntil ? (
            <ReturnTooLate body={getRichtext(data, 'return-expired', locale)} />
          ) : (
            <ReturnOther body={getRichtext(data, 'return-generic', locale)} />
          )
        ) : step === STEP.PAYMENT ? (
          <PaymentStep />
        ) : step === STEP.DONE ? (
          <ReadyStep
            body={getRichtext(data, 'return-done', locale)}
            bodyAlt={getRichtext(data, 'return-done-pickup', locale)}
          />
        ) : step === STEP.NOT_SET ? (
          <Box></Box>
        ) : null}
      </Container>
    </AccountLayout>
  );
};

export const returnTextQuery = graphql`
  query {
    allContentfulLayoutRichText(
      filter: {
        slug: {
          in: [
            "return-code"
            "return-code-valid"
            "return-no-code"
            "return-expired"
            "return-generic"
            "return-done"
            "return-done-pickup"
          ]
        }
      }
    ) {
      edges {
        node {
          contentful_id
          node_locale
          title
          slug
          body {
            raw
            references {
              ... on ContentfulAsset {
                ...assetFields
              }
              ... on ContentfulPage {
                ...pageLink
              }
              ... on ContentfulBlogPost {
                ...blogLink
              }
            }
          }
        }
      }
    }
  }
`;
