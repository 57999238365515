/** @jsx jsx */
import { jsx, Flex, Box, Radio, Label } from 'theme-ui';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import Button from '../components/Button';
import { SettingsIcon, InfoIcon } from '../components/Icon';
import { toggleService, toggleMultipleServices } from '../state/servicePoints';
import Switch from '../components/Switch';
import Tooltip from '../components/Tooltip';

const filterableServices = [
  // 'SL', // Paketin lähettäminen: sopimusasiakkaat
  //'M1', // Lipunmyynti
  'M2', // Matkakortin ostaminen
  'M3', // Matkakortin lataus
  //'WU', // Western Union -rahansiirtopalvelu
];

const serviceTooltipsTranslated = translate => ({
  P2: (
    <div>
      {translate('servicePoints.tooltip.allPoints')}
      <br />
      <br />- {translate('servicePoints.tooltip.max30kg')}
      <br />- {translate('servicePoints.tooltip.max70cm')}
    </div>
  ),
  'P2,-EP': (
    <div>
      {translate('servicePoints.tooltip.allButLockers')}
      <br />
      <br />- {translate('servicePoints.tooltip.max30kg')}
      <br />- {translate('servicePoints.tooltip.max200cm')}
      <br />- {translate('servicePoints.tooltip.maxVolume')}
    </div>
  ),
  P3: <div>{translate('servicePoints.tooltip.express')}</div>,
  P35: (
    <div>
      {translate('servicePoints.tooltip.allButLockers')}
      <br />
      <br />- {translate('servicePoints.tooltip.max35kg')}
      <br />- {translate('servicePoints.tooltip.max230cm')}
    </div>
  ),
  EP: (
    <div>
      {translate('servicePoints.tooltip.lockers')}
      <br />
      <br />- {translate('servicePoints.tooltip.max30kg')}
      <br />- {translate('servicePoints.tooltip.max70cm')}
    </div>
  ),
});

const ServicePointFilters = ({ showFilters, setShowFilters, services: allServices, showP2 }) => {
  useEffect(() => {
    if (showFilters) {
      setShowFilters(true);
    }
  }, [showFilters]);

  const dispatch = useDispatch();
  const services = useSelector(state =>
    filterableServices.map(f => (state.servicePoints.services || []).find(s => s.code === f)).filter(s => s)
  );

  function onCheck(e) {
    dispatch(toggleService(e.target.id));
  }

  function toggleServices(e) {
    e.preventDefault();
    setShowFilters(!showFilters);
  }

  const translate = getTranslate(useSelector(state => state.localize));
  const serviceTooltips = serviceTooltipsTranslated(translate);

  const packetFilters = {
    P2: translate('servicePoints.lightSmall'),
    'P2,-EP': translate('servicePoints.lightAll'),
    EP: translate('servicePoints.onlyAutomats'),
    //P3: translate('servicePoints.express'),
    P35: translate('servicePoints.allPackets'),
    D1: translate('servicePoints.dhlExpress'),
  };
  if (!showP2) {
    delete packetFilters.P2;
  }
  const selectedPacketFilter = Object.keys(packetFilters)
    .reverse()
    .find(codes => codes.split(',').every(code => allServices.some(s => s.code === code && s.selected)));
  const [packetFiltersVisible, setPacketFiltersVisible] = useState(false);

  const togglePacketFilters = useCallback(
    e => {
      if (e.target.checked) {
        setPacketFiltersVisible(true);
      } else {
        setPacketFiltersVisible(false);
        const packetServices = {
          P2: false,
          P3: false,
          P35: false,
          '-EP': false,
          EP: false,
          D1: false,
        };
        dispatch(toggleMultipleServices(packetServices));
      }
    },
    [setPacketFiltersVisible, dispatch]
  );

  const selectPacketFilter = useCallback(
    e => {
      const packetServices = {
        P2: false,
        P3: false,
        P35: false,
        '-EP': false,
        EP: false,
        D1: false,
      };
      e.target.id.split(',').forEach(s => (packetServices[s] = true));
      dispatch(toggleMultipleServices(packetServices));
    },
    [dispatch]
  );

  const filterCount = services.filter(s => s.selected).length + (selectedPacketFilter ? 1 : 0);

  return (
    <>
      {services && (
        <Box
          sx={{
            py: 3,
            px: 1,
          }}
        >
          <Button
            variant="plain"
            type="button"
            onClick={toggleServices}
            sx={{
              flex: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              color: 'secondary',
              textAlign: 'left',
              borderRadius: 0,
              p: 0,
              width: '100%',
            }}
          >
            <SettingsIcon sx={{ width: 20, height: 20, mr: 2 }} />
            <Box sx={{ flex: 1 }}>
              {translate('servicePoints.filter')}{' '}
              {filterCount > 0 && <span sx={{ color: 'primary' }}>({filterCount})</span>}
            </Box>
            <Box
              as="svg"
              width="11"
              height="16"
              viewBox="0 0 11 16"
              xmlns="http://www.w3.org/2000/svg"
              sx={{
                display: 'block',
                justifySelf: 'flex-end',
                transform: showFilters ? 'rotate(-90deg)' : 'rotate(90deg)',
                mr: 2,
              }}
            >
              <path
                d="M10.333 8c0 .441-.189.861-.52 1.153l-7.434 6.54a1.231 1.231 0 01-1.626-1.848l6.501-5.72a.167.167 0 000-.25L.753 2.155A1.231 1.231 0 012.379.307L9.81 6.845c.332.292.522.713.522 1.155z"
                fill="currentColor"
              />
            </Box>
          </Button>
          {showFilters && (
            <Box sx={{ mt: 24, mb: 2 }}>
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  mb: 2,
                  ':last-of-type': { mb: 0 },
                }}
              >
                <Switch
                  id="packet"
                  checked={!!selectedPacketFilter || packetFiltersVisible}
                  onChange={togglePacketFilters}
                  sx={{}}
                >
                  {translate('servicePoints.parcelFilter')}
                </Switch>
              </Flex>
              {(selectedPacketFilter || packetFiltersVisible) && (
                <Box
                  sx={{
                    mt: 2,
                    mb: 3,
                    px: 2,
                    py: 3,
                    bg: '#f5f6f8',
                    borderRadius: 1,
                  }}
                >
                  {Object.entries(packetFilters).map(([code, description]) => (
                    <Flex key={code} sx={{ my: 2 }}>
                      <Label>
                        <Radio id={code} checked={selectedPacketFilter === code} onChange={selectPacketFilter} />
                        {description}
                      </Label>
                      {serviceTooltips.hasOwnProperty(code) && (
                        <Tooltip placement="right" tooltip={serviceTooltips[code]}>
                          <InfoIcon />
                        </Tooltip>
                      )}
                    </Flex>
                  ))}
                </Box>
              )}

              {services.map(({ code, description, selected }) => (
                <Flex
                  key={code}
                  sx={{
                    justifyContent: 'space-between',
                    mb: 2,
                    ':last-of-type': { mb: 0 },
                  }}
                >
                  <Switch id={code} checked={selected} onChange={onCheck} sx={{}}>
                    {description}
                  </Switch>
                  {serviceTooltips.hasOwnProperty(code) && (
                    <Tooltip placement="right" tooltip={serviceTooltips[code]}>
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Flex>
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

ServicePointFilters.propTypes = {
  hideFilters: PropTypes.bool,
};

ServicePointFilters.defaultProps = {
  hideFilters: true,
};

export default ServicePointFilters;
