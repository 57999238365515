exports.getLinkForPage = page => {
  if (!page || !page.slug) return '';
  const slugs = [`/${page.slug.trim()}`];
  let parentPage = page.parentPage;
  while (parentPage && parentPage.slug) {
    slugs.unshift(`/${parentPage.slug.trim()}`);
    parentPage = parentPage.parentPage;
  }
  let link = slugs.join('');
  return link;
};
