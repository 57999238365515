/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../../components/Link';
import { Button } from '..';
import Widget from './Widget';
import themes from './themes';

export default props => {
  const { title, description, ctaTitle, ctaLink, visualStyle } = props.data;
  const translate = getTranslate(useSelector(state => state.localize));
  const theme = themes?.[visualStyle] || themes['Bluelight'];

  return (
    <Widget title={title ? title : translate('seriesWidget.title')} titleColor={theme.titleColor} {...props}>
      <Box>{description ? description.description : translate('seriesWidget.description')}</Box>
      <Flex sx={{ flexWrap: 'wrap', fontSize: 2, mt: 3 }}>
        <Button variant="smallSecondary" as={Link} to={ctaLink ? ctaLink : '/osta-sarjapaketti'}>
          {ctaTitle ? ctaTitle : translate('seriesWidget.buySeriesPackage')}
        </Button>
        {!props.noSendButton && (
          <Button
            as={Link}
            to="/multi-parcel"
            variant="plain"
            sx={{
              fontSize: 1,
              color: theme.color,
              pl: 3,
              py: 2,
            }}
          >
            {translate('seriesWidget.sendSeriesPackage')} &rarr;
          </Button>
        )}
      </Flex>
    </Widget>
  );
};
