/** @jsx jsx */
import React from 'react';
import { jsx, Box, Styled } from 'theme-ui';
import PropTypes from 'prop-types';
import moment from 'moment';
import fiWeekdays from 'moment/locale/fi';
import svWeekdays from 'moment/locale/sv';
import { InfoIcon } from '../components/Icon';
import Tooltip from '../components/Tooltip';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import { capitalize } from '../utils/string';

const combineWeekdays = (listOfDays, propGetter, language, strFormatter) => {
  const weekdaysFi = ['Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai', 'Sunnuntai'];
  const weekdaysSv = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
  const weekdaysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const getDayIndex = obj =>
    weekdaysFi.indexOf(obj.weekDay) + 1 ||
    weekdaysSv.indexOf(obj.weekDay) + 1 ||
    weekdaysEn.indexOf(obj.weekDay) + 1 ||
    0;
  const sorted = listOfDays.sort((a, b) => {
    const aIndex = getDayIndex(a);
    const bIndex = getDayIndex(b);
    return aIndex - bIndex;
  });

  const slots = [];
  sorted.forEach(day => {
    const weekday = getDayIndex(day) || day.weekDay;
    const value = propGetter(day);
    let slot = slots.find(s => s.value === value);
    if (!slot) {
      slot = {
        value,
        weekdays: [weekday],
      };
      slots.push(slot);
    } else {
      slot.weekdays.push(weekday);
    }
  });

  return slots.map((slot, index) => {
    const dayStr = slot.weekdays.reduce((str, weekday, i) => {
      const isoWeekday =
        typeof weekday === 'number'
          ? capitalize(
              moment()
                .isoWeekday(weekday)
                .locale(language)
                .format('dd')
            )
          : weekday;

      if (i === 0) {
        return isoWeekday;
      } else if (slot.weekdays[i - 1] != weekday - 1) {
        return `${str}, ${isoWeekday}`;
      } else if (i === slot.weekdays.length - 1 || slot.weekdays[i + 1] !== weekday + 1) {
        return `${str}\u2013${isoWeekday}`;
      } else {
        return str;
      }
    }, '');
    return strFormatter(dayStr, slot.value);
  });
};

const CutoffTimes = ({ cutoffTimes }) => {
  const language = useLanguage();
  const translate = useTranslate();
  if (!cutoffTimes || cutoffTimes.length === 0) {
    return null;
  }
  const formatter = (day, time) => translate('servicePoints.parcelServices.before', { day, time });
  const lines = combineWeekdays(cutoffTimes, v => v.cutoffTime, language, formatter);

  return (
    <Box>
      <Styled.h5 sx={{ mt: 3, mb: 1 }}>{translate('servicePoints.parcelServices.cutoffTime')}</Styled.h5>
      <div sx={{ float: 'right' }}>
        <Tooltip
          placement="auto"
          tooltip={
            <div>
              <p sx={{ color: 'white' }}>{translate('servicePoints.parcelServices.cutoffTooltip')}</p>
              <p sx={{ color: 'white' }}>{translate('servicePoints.parcelServices.cutoffTooltip2')}</p>
            </div>
          }
        >
          <InfoIcon />
        </Tooltip>
      </div>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </Box>
  );
};

export const OpenTimes = ({ openTimes }) => {
  const language = useLanguage();
  if (!Array.isArray(openTimes) || openTimes.length === 0) return null;

  const formatter = (day, time) => `${day} ${time}`;
  const lines =
    typeof openTimes[0] === 'string'
      ? openTimes
      : combineWeekdays(openTimes, v => `${v.openTime}\u2013${v.closeTime}`, language, formatter);
  return (
    <>
      {lines.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
    </>
  );
};

const OpenTimesAndTitle = ({ openTimes }) => {
  const translate = useTranslate();
  if (!Array.isArray(openTimes)) {
    return null;
  }
  return (
    <Box>
      <Styled.h5 sx={{ mt: 3, mb: 1 }}>{translate('servicePoints.openTimes')}</Styled.h5>
      <OpenTimes openTimes={openTimes} />
    </Box>
  );
};

function ServiceDetails({ type, officeType, showTitle, openTimes, cutoffTimes, alertRemarks, normalRemarks }) {
  const translate = useTranslate();

  return (
    <Box sx={{ my: 3 }}>
      {showTitle && (
        <Styled.h4 sx={{ mt: 3, mb: 3 }}>
          {type == 'Matkapalvelut'
            ? translate('servicePoints.travelServices.title')
            : type == 'Pakettipalvelut'
            ? translate('servicePoints.parcelServices.title')
            : type}
        </Styled.h4>
      )}
      <OpenTimesAndTitle openTimes={openTimes} />
      <CutoffTimes cutoffTimes={cutoffTimes} />
      {alertRemarks && (
        <Box
          sx={{
            bg: '#F9EAEC',
            color: '#8C1021',
            borderRadius: 1,
            px: 3,
            py: 3,
          }}
        >
          {alertRemarks}
        </Box>
      )}
      {/** 
      <Box sx={{ my: 3 }}>
        {props.type == 'Matkapalvelut' && (
          <Box>
            {translate('servicePoints.travelServices.phone1')}
            <a href="tel:02004000"> 0200 4000</a>
            <Box sx={{ fontSize: 1 }}>
              {translate('servicePoints.travelServices.phone2')}
              {translate('servicePoints.travelServices.phone3')}
            </Box>
          </Box>
        )}
        {props.type == 'Pakettipalvelut' && (
          <Box>
            {translate('servicePoints.parcelServices.phone1')}
            <a href="tel:060011300"> 0600 11300</a>
            <Box sx={{ fontSize: 1 }}>
              {translate('servicePoints.parcelServices.phone2')}
              {translate('servicePoints.parcelServices.phone3')}
            </Box>
          </Box>
        )}
        {props.phoneNumber}
      </Box>
      */}
      {/**showPickupOrderLink && (
        <Box>
          <Link to="/pickup-order">
            {translate('servicePoints.parcelServices.pickup')}
          </Link>
        </Box>
      )*/}
      <Box sx={{ my: 3 }}>{normalRemarks}</Box>
    </Box>
  );
}

ServiceDetails.propTypes = {
  type: PropTypes.string,
  officeType: PropTypes.string,
  openTimes: PropTypes.arrayOf(PropTypes.string),
  normalRemarks: PropTypes.string,
  alertRemarks: PropTypes.string,
};

export default ServiceDetails;
