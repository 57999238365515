/** @jsx jsx */
import { jsx, Box, Flex } from 'theme-ui';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import ServicePointContainer from './ServicePointContainer';
import { capitalize } from '../utils/string';
import useWindowDimensions from '../hooks/useWindowDimensions';

const computeDistance = (l, lat, lon) => {
  const { latitude, longitude } = l;
  const dy = Math.abs(latitude - lat);
  const dx = Math.abs(longitude - lon) * Math.cos((((lat + latitude) / 2) * Math.PI) / 180); // rough estimate
  return Math.sqrt(dx * dx + dy * dy) * 111.2;
};

const orderServicePointList = (allPoints, highestPriority, focusPoint, viewRect) => {
  if (!highestPriority || highestPriority.length === 0) {
    return allPoints;
  }
  let { latitude, longitude } = highestPriority[0];
  if (focusPoint) {
    latitude = focusPoint.lat;
    longitude = focusPoint.lng;
  }

  let visibleTop5 = highestPriority.filter(h => !!allPoints.find(p => p.officeCode === h.officeCode));
  if (viewRect) {
    const { left, right, top, bottom } = viewRect;
    visibleTop5 = visibleTop5.filter(
      p => p.longitude > left && p.longitude < right && p.latitude > bottom && p.latitude < top
    );

    // if searched address if beyound map view, compute distance from map center
    if (longitude < left || longitude > right || latitude < bottom || latitude > top) {
      longitude = (left + right) / 2;
      latitude = (top + bottom) / 2;
    }
  }

  const distances = {};
  allPoints.forEach(p => (distances[p.id] = computeDistance(p, latitude, longitude)));

  return [
    ...visibleTop5,
    ...allPoints
      .filter(p => !highestPriority.find(h => h.officeCode === p.officeCode))
      .sort((a, b) => {
        const da = distances[a.officeCode];
        const db = distances[b.officeCode];
        return da < db ? -1 : +1;
      }),
  ];
};

function ServicePointList({ servicePoints, priorityPoints, onSelect, viewRect, limitOptionsOnMobile = false }) {
  const [sortedServicePoints, setSortedServicePoints] = useState([]);
  const focusPoint = useSelector(state => state.servicePoints.focus);
  const translate = getTranslate(useSelector(state => state.localize));
  const { width } = useWindowDimensions();

  const showAFewPointsOnly = useMemo(() => {
    return width < 768 && limitOptionsOnMobile;
  }, [width, limitOptionsOnMobile]);

  useEffect(() => {
    const sorted = orderServicePointList(servicePoints, priorityPoints, focusPoint, viewRect);
    const sliceAt = showAFewPointsOnly ? 3 : 50;
    setSortedServicePoints(sorted.slice(0, sliceAt)); // list at most 50 offices
  }, [servicePoints, priorityPoints, showAFewPointsOnly]);

  const renderOfficeType = useCallback(
    (type, type2) => {
      const props = { sx: { fontSize: 'small' }, mb: 1 };
      const isTest = process.env.GATSBY_ACTIVE_ENV !== 'production';
      if (['STATION'].includes(type)) {
        // noutopiste type MHA
        if (isTest && type2 && type2 !== 'MHA') {
          console.log('officetype', type, type2);
        }
        return <Box {...props}>{translate('servicePoints.type.own')}</Box>;
      } else if (['AGENT'].includes(type)) {
        // noutopiste type MHM
        if (isTest && type2 && type2 !== 'MHM') {
          console.log('officetype', type, type2);
        }
        return <Box {...props}>{translate('servicePoints.type.agent')}</Box>;
      } else if (['STORE', 'RKIOSK', 'FETCHOFFICE'].includes(type)) {
        // noutopiste type MHN (or TRS, TRV)
        if (isTest && type2 && type2 !== 'MHN' && type2 !== 'TRS' && type2 !== 'TRV') {
          console.log('officetype', type, type2);
        }
        return <Box {...props}>{translate('servicePoints.type.store')}</Box>;
      } else if (['EASYPACK'].includes(type)) {
        // noutopiste type MHE
        if (isTest && type2 && type2 !== 'MHE') {
          console.log('officetype', type, type2);
        }
        return null;
      } else {
        if (isTest) {
          console.log('unknown office type', type);
        }
        return null;
      }
    },
    [translate]
  );

  if (!servicePoints) return null;

  return (
    <>
      {servicePoints && servicePoints.length > 0 && (
        <ServicePointContainer>
          {sortedServicePoints.map((point, index) => (
            <Box
              key={index}
              id={index}
              onClick={() => onSelect(point)}
              sx={{
                cursor: 'pointer',
                py: 3,
                lineHeight: 'heading',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderColor: 'border',
                ':hover': {
                  color: 'primary',
                },
                ':last-of-type': {
                  border: 0,
                },
              }}
            >
              {Number.isFinite(point.distance) && <Box sx={{ float: 'right' }}>{point.distance} km</Box>}
              <Box sx={{ fontWeight: 'medium' }}>{point.officeName}</Box>
              {point?.officeChain === 'IBOXEN' && (
                <Flex sx={{ mt: 1, mb: 2, flexDirection: 'row' }}>
                  <Box sx={{ bg: 'lightYellow', py: 0, px: 2, borderRadius: 1 }}>
                    <span sx={{ color: 'black' }}>{translate('servicePoints.appOnly')}</span>
                  </Box>
                </Flex>
              )}
              {renderOfficeType(point.officeType, point.type)}
              <Box>
                {capitalize(point.officeStreetAddress)}, {point.officePostalCode} {capitalize(point.officeCity)}
              </Box>
            </Box>
          ))}
        </ServicePointContainer>
      )}
    </>
  );
}

ServicePointList.propTypes = {
  servicePoints: PropTypes.arrayOf(PropTypes.object),
  priorityPoints: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func,
};

export default ServicePointList;
