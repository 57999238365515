/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { formatPrice, formatSize } from '../utils/formatters';
import { imagePathForProduct, SizeCode } from '../state/catalog';
import Tooltip from '../components/Tooltip';
import { InfoIcon } from '../components/Icon';
import getLanguage from '../utils/getLanguage';

export default ({ product, altProduct, isSelected, isCurrentSize }) => {
  const language = useSelector(state => getLanguage(state));
  const translate = getTranslate(useSelector(state => state.localize));
  let { displayName, sizeCode, localizedName, deliveryIncluded } = product;
  const isSeriesProduct = product.quantity && product.quantity > 1;
  let tooltip;
  if (localizedName && localizedName[language]) {
    displayName = localizedName[language];
  }
  if ([SizeCode.REPACK, SizeCode.REFILL].includes(sizeCode)) {
    displayName = translate('buyParcel.repack.nameOnCard');
    tooltip = (
      <Flex sx={{ flexDirection: 'column', div: { my: 2 } }}>
        <div>{translate('buyParcel.repack.tooltip1')}</div>
        <div>{translate('buyParcel.repack.tooltip2')}</div>
        <div>{translate('buyParcel.repack.tooltip3')}</div>
      </Flex>
    );
  }
  let maxSize = product.maxSize && (
    <>
      {translate('buyParcel.product.maxSize')}
      <br />
      {formatSize(product.maxSize)}
    </>
  );
  if (product.maxSideSum) {
    maxSize = <span>{translate('buyParcel.product.flexible', { ...product })}</span>;
  }
  if (sizeCode == SizeCode.BIKE) {
    maxSize = (
      <span
        dangerouslySetInnerHTML={{
          __html: translate('buyParcel.product.bikeSize'),
        }}
      />
    );
  }

  // PAK-719: added "nouto ja toimitus ovelle" label for domestic parcels if delivery / pickup included
  const includesDeliveryAndPickup =
    product?.deliveryIncluded && product?.pickupIncluded && (product?.countryCode === 'FI' || !product?.countryCode);

  const mailboxDelivery = !!product?.mailboxDelivery;

  return (
    <Flex
      sx={{
        position: 'relative',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 160,
        height: '100%',
        minHeight: 264,
        maxHeight: 360,
        pt: 2,
        pb: 3,
        px: 2,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        borderRadius: 1,
        color: isSelected ? 'white' : 'inherit',
        backgroundColor: isSelected ? 'primary' : 'white',
        ':hover': {
          borderColor: isSelected ? 'primary' : 'grayLight',
          cursor: 'pointer',
        },
        img: {
          filter: isSelected
            ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(138deg) brightness(99%) contrast(103%)'
            : 'none',
        },
      }}
    >
      {(isCurrentSize || deliveryIncluded || mailboxDelivery) && (
        <div
          sx={{
            bg: isCurrentSize ? 'blueLighter' : 'secondary',
            color: isCurrentSize ? 'secondary' : 'white',
            borderRadius: 1,
            py: 1,
            px: 2,
            fontSize: '11px',
            alignSelf: 'start',
            mt: '-4px',
            ml: '-5px',
          }}
        >
          {translate(
            mailboxDelivery
              ? 'buyParcel.services.JPL.title'
              : isCurrentSize
              ? 'buyParcel.product.currentSize'
              : includesDeliveryAndPickup
              ? 'buyParcel.product.includesDeliveryAndPickup'
              : 'buyParcel.product.includesDelivery'
          )}
        </div>
      )}
      {tooltip && (
        <div sx={{ position: 'absolute', top: 2, right: 2 }}>
          <Tooltip placement="auto" tooltip={tooltip}>
            <InfoIcon />
          </Tooltip>
        </div>
      )}
      <Flex
        sx={{
          flex: 'none',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Flex
          sx={{
            justifyContent: 'center',
            alignItems: 'flex-end',
            width: 96,
            height: 96,
          }}
        >
          <img src={imagePathForProduct(product.sizeCode)} alt={displayName} width="96" height="96" />
        </Flex>
        <Box
          sx={{
            mt: 2,
            fontSize: 2,
            fontWeight: 'medium',
            textAlign: 'center',
            lineHeight: 1.3,
          }}
        >
          {isSeriesProduct ? (
            <>
              {translate('buyParcel.product.seriesShipment')}
              <br />
              {product.quantity} x {displayName}
            </>
          ) : (
            <span>{displayName}</span>
          )}
        </Box>
      </Flex>
      <Box
        sx={{
          my: 2,
          fontSize: isCurrentSize ? 3 : 4,
          fontWeight: 'heading',
          textAlign: 'center',
          color: isSelected ? 'white' : 'primary',
        }}
      >
        {[SizeCode.REPACK, SizeCode.REFILL].includes(sizeCode) ? (
          <>
            <Box
              sx={{
                fontSize: 1,
                textAlign: 'center',
                fontWeight: 'medium',
                mb: -1,
              }}
            >
              {translate('buyParcel.product.startPrice')}
            </Box>
            {sizeCode == SizeCode.REPACK ? formatPrice(altProduct?.price) : formatPrice(product.price)}
          </>
        ) : isCurrentSize ? (
          translate('buyParcel.product.addServices')
        ) : product.price > 0 ? (
          formatPrice(product.price)
        ) : (
          translate('buyParcel.product.free')
        )}
        {/*altProduct && (
          <div sx={{ fontSize: 1, mt: '-4px' }}>
            ({translate('buyParcel.repack.or')} {formatPrice(altProduct.price)})
          </div>
        )*/}
      </Box>
      {maxSize ? <Box sx={{ fontSize: 1, textAlign: 'center' }}>{maxSize}</Box> : <Box sx={{ fontSize: 1 }}></Box>}
    </Flex>
  );
};
