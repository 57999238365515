/** @jsx jsx */
import { jsx, Styled, Flex } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { useSelector } from 'react-redux';
import { ParcelWidget } from '.';
import { getTranslate } from 'react-localize-redux';

export default ({ locale, sxx, showHeader = true }) => {
  const translate = getTranslate(useSelector(state => state.localize));

  const data = useStaticQuery(graphql`
    query {
      allContentfulPageSection(filter: { contentType: { eq: "Product" } }) {
        edges {
          node {
            node_locale
            content {
              ... on ContentfulProduct {
                id
                name
                maxSize
                price
                productId
                countryCode
                productType
              }
            }
          }
        }
      }
    }
  `);
  if (!data || !data.allContentfulPageSection) {
    return null;
  }

  let liftups = data.allContentfulPageSection.edges.find(e => e.node.node_locale.startsWith(locale));
  if (!liftups) {
    liftups = data.allContentfulPageSection.edges.find(e => e.node.node_locale === 'fi-FI');
  }
  if (!liftups) {
    return null;
  }
  const { content } = liftups.node;

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: ['flex-start', 'center'],
        ...sxx,
      }}
    >
      {showHeader && <Styled.h2 sx={{ variant: 'text.title', mt: 0 }}>{translate('sendInSmallMoney')}</Styled.h2>}
      <Flex
        sx={{
          overflowX: 'auto',
          maxWidth: '100%',
          scrollSnapType: 'x mandatory',
          mb: 2,
          mt: 3,
          MsOverflowStyle: '-ms-autohiding-scrollbar',
        }}
      >
        {content.map(p => (
          <ParcelWidget key={p.id} {...p} />
        ))}
      </Flex>
    </Flex>
  );
};
