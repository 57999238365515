/** @jsx jsx */
import { jsx, Flex, Box, Styled, Radio, Label } from 'theme-ui';
import React, { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

import SizeChecker from './SizeChecker';
import Button from '../components/Button';
import FullHeightColumn from '../components/FullHeightColumn';
import Spinner from '../components/Spinner';
import { useCartHasOnlyExpressParcels } from '../state/basket';
import {
  askPackageCount,
  goBack,
  goNext,
  selectLarge,
  selectPackageCount,
  selectProduct,
  ParcelType,
  combineExpressService,
  toggleService,
} from '../state/parcelFlow';
import ProductCard from './ProductCard';
import Switch from '../components/Switch';
import {
  SizeCode,
  multiParcelProduct,
  areProductsLoaded,
  loadProductsForCountry,
  ServiceCode,
  imagePathForProduct,
} from '../state/catalog';
import { locNavigate } from '../state/session';
import CheckRepackCode from './CheckRepackCode';
import Link from '../components/Link';
import { formatPrice } from '../utils/formatters';
import { useEffect } from 'react';
import { isFinlandOrAland } from '../utils/countryCode';
import Accordion from '../components/Accordion';
import { useLanguage } from '../utils/getLanguage';

const filterProducts = (state, isLarge, buySeries, repackRefill, translate) => {
  const {
    parcelFlow: { country, parcelType },
    catalog: { products: productsForFinland, country: productsForCountry },
  } = state;
  const products = (country === 'FI' ? productsForFinland : productsForCountry[country]) || [];
  if (!isFinlandOrAland(country)) {
    return products;
  }

  if (!buySeries) {
    const repack = products.find(p => p.sizeCode === SizeCode.REPACK);
    const result = Object.values(
      (products || []).filter(
        p => (p.isLarge === isLarge || (isLarge && p.showAlways)) && p !== repack // RePack and ReFill are different products but only one product card is shown to user
      )
    );
    if (parcelType === ParcelType.Express) {
      return result.flatMap(p => combineExpressService(p, translate) || []);
    } else {
      return result.map(p => {
        if (p.sizeCode === SizeCode.REFILL && !repackRefill && repack) {
          // map refill to repack if needed
          return repack;
        } else {
          return p;
        }
      });
    }
  } else {
    // todo get available series products and pricing
    return Object.values(
      (products || []).filter(p => (p.isLarge === isLarge || (isLarge && p.showAlways)) && !p.noMultiParcel)
    ).map(p => {
      return multiParcelProduct(p);
    });
  }
};

const getRepactProducts = state => {
  const {
    parcelFlow: { country },
    catalog: { products: productsForFinland, country: productsForCountry },
  } = state;
  const products = (country === 'FI' ? productsForFinland : productsForCountry[country]) || [];
  return [products.find(p => p.sizeCode === SizeCode.REPACK), products.find(p => p.sizeCode === SizeCode.REFILL)];
};

const RePackSelection = ({ repackRefill, setRepackRefill }) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const [repackProduct, refillProduct] = useSelector(state => getRepactProducts(state));

  const selectBuy = useCallback(() => {
    dispatch(selectProduct(repackProduct));
    setRepackRefill(false);
  }, [dispatch, repackProduct, setRepackRefill]);
  const selectReuse = useCallback(() => {
    dispatch(selectProduct(refillProduct));
    setRepackRefill(true);
  }, [dispatch, refillProduct, setRepackRefill]);

  if (!repackProduct && !refillProduct) {
    return null;
  }

  return (
    <Box
      sx={{
        mb: 3,
        mx: [-3, 0],
        p: 3,
        borderRadius: [0, 2],
        borderStyle: 'solid',
        borderColor: 'grayLight',
        borderWidth: 2,
        maxWidth: 900,
      }}
    >
      <Box sx={{ mb: 3, fontWeight: 'medium' }}>
        {translate('buyParcel.repack.description')}{' '}
        <Link to="/paketit/matkahuolto-x-repack">{translate('buyParcel.repack.readMore')}</Link>
      </Box>
      {!!repackProduct && (
        <Flex sx={{ mb: 3 }}>
          <Label sx={{ fontWeight: 'normal' }}>
            <Radio checked={!repackRefill} onChange={selectBuy} />
            {translate('buyParcel.repack.buy')}
          </Label>
          <Box
            sx={{
              fontWeight: 'heading',
              color: 'primary',
              flex: 'none',
            }}
          >
            {formatPrice(repackProduct.price)}
          </Box>
        </Flex>
      )}
      {!!refillProduct && (
        <Flex sx={{ mb: 1 }}>
          <Label sx={{ fontWeight: 'normal' }}>
            <Radio checked={repackRefill} onChange={selectReuse} />
            {translate('buyParcel.repack.reuse')}
          </Label>
          <Box
            sx={{
              fontWeight: 'heading',
              color: 'primary',
              flex: 'none',
            }}
          >
            {formatPrice(refillProduct.price)}
          </Box>
        </Flex>
      )}
      {repackRefill && <CheckRepackCode />}
    </Box>
  );
};

const PackageCountQuestion = () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const numberOfPackages = useSelector(state => state.parcelFlow.packages) || false;

  return (
    <Box
      sx={{
        display: 'block',
        my: [2],
      }}
    >
      <Box>
        {translate('buyParcel.product.tireDescription')}
        <br />
        {translate('buyParcel.product.packages')}
      </Box>
      <Box sx={{ display: 'flex', my: 2 }}>
        {[1, 2, 3, 4, 5].map(count => {
          return (
            <Box
              key={'r' + count}
              sx={{
                position: 'relative',
                minWidth: 40,
                height: 40,
                mr: 1,
              }}
            >
              <input
                type="radio"
                name="packages"
                checked={count == numberOfPackages}
                onChange={() => dispatch(selectPackageCount(count))}
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  cursor: 'pointer',
                  appearance: 'none',

                  ':checked + label': {
                    bg: 'primary',
                    color: 'white',
                    borderColor: 'primary',
                  },
                  ':not(:checked):hover + label': {
                    borderColor: 'gray',
                  },
                }}
              />
              <label
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                  borderStyle: 'solid',
                  borderWidth: 2,
                  borderColor: 'grayLight',
                  borderRadius: 3,
                  fontWeight: 'medium',
                }}
                onClick={() => dispatch(selectPackageCount(count))}
              >
                {count}
              </label>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export const ariaLabelForProduct = (product, translate) => {
  let label = '';
  if (product.quantity > 1) {
    label = `sarjapaketti ${product.quantity} kappaletta `;
  }
  label += product.sizeCode + ' paketti ';
  label += product.price + ' €';

  if (product.maxSize) {
    const { height, width, depth } = product.maxSize;
    label += `, koko ${height} kertaa ${width} kertaa ${depth} cm`;
  }

  return label;
};

const ProductList = ({ products, productsLoaded }) => {
  const dispatch = useDispatch();
  const translate = getTranslate(useSelector(state => state.localize));
  const container = useRef();
  const [repackProduct, refillProduct] = useSelector(state => getRepactProducts(state));
  const selectedProduct = useSelector(state => state.parcelFlow.product) || {};
  const [focusIndex, setFocusIndex] = useState(0);

  const onKeyDown = useCallback(
    e => {
      let elements = [];
      if (container.current) {
        elements = Array.from(container.current.querySelectorAll('.product'));
      }

      switch (e.key) {
        case 'ArrowUp':
        case 'ArrowLeft':
          setFocusIndex(i => {
            const newIndex = i > 0 ? i - 1 : products.length - 1;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case 'ArrowDown':
        case 'ArrowRight':
          setFocusIndex(i => {
            const newIndex = i < products.length - 1 ? i + 1 : 0;
            const elem = elements[newIndex];
            if (elem) {
              elem.focus();
            }
            return newIndex;
          });
          e.preventDefault();
          break;
        case ' ':
        case 'Enter':
          setFocusIndex(i => {
            const product = products[i];
            if (product) {
              setImmediate(() => {
                dispatch(selectProduct(product));
              });
            }
            return i;
          });
          e.preventDefault();
          break;
      }
    },
    [products, setFocusIndex, dispatch]
  );

  return (
    <Flex
      ref={container}
      sx={{
        flexWrap: ['nowrap', null, 'wrap'],
        overflowX: ['auto', null, 'visible'],
        mb: 3,
        mx: [-3, null, 0],
      }}
      role="radiogroup"
      aria-label={translate('buyParcel.product.title')}
      onKeyDown={onKeyDown}
    >
      {!productsLoaded && (
        <div sx={{ x: 1 }}>
          <Spinner />
        </div>
      )}
      {productsLoaded && products.length === 0 ? 'Tuotteiden lataaminen epäonnistui.' : null}
      {products.map((p, index) => (
        <Box
          key={p.id}
          onClick={() => dispatch(selectProduct(p))}
          sx={{
            flex: 'none',
            mr: 2,
            mb: 2,
            ':first-of-type': {
              pl: [3, null, 0],
            },
            ':last-of-type': {
              pr: 3,
            },
          }}
          className="product"
          role="radio"
          aria-label={ariaLabelForProduct(p, translate)}
          aria-checked={p.id === selectedProduct.id}
          tabIndex={focusIndex === index ? 0 : -1}
        >
          <ProductCard
            product={p}
            altProduct={
              p.sizeCode === SizeCode.REPACK ? refillProduct : p.sizeCode === SizeCode.REFILL ? repackProduct : null
            }
            isSelected={p.id === selectedProduct.id}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default () => {
  const language = useLanguage();
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const countryCode = useSelector(state => state.parcelFlow.country || 'FI');
  const sendAbroad = !isFinlandOrAland(countryCode);
  const parcelType = useSelector(state => state.parcelFlow.parcelType);
  const isLarge = useSelector(state => state.parcelFlow.isLarge) || false;
  const repackCode = useSelector(state => state.parcelFlow.repackCode) || '';
  const numberOfPackages = useSelector(state => state.parcelFlow.packages) || false;
  const buySeries = useSelector(state => state.parcelFlow.buySeries) || false;
  const selectedProduct = useSelector(state => state.parcelFlow.product) || {};
  const [repackRefill, setRepackRefill] = useState(selectedProduct.sizeCode === SizeCode.REFILL);
  const products = useSelector(state => filterProducts(state, isLarge, buySeries, repackRefill, translate));
  const selectedServices = useSelector(state => state.parcelFlow.services);

  const alreadyInBasket = useSelector(state => !!state.parcelFlow.rowId);
  const onlyExpressParcels = useCartHasOnlyExpressParcels();
  const hideBackButton = parcelType === ParcelType.Express && onlyExpressParcels;
  const backToShoppingBasket = useCallback(() => {
    dispatch(locNavigate('/ostoskori'));
  }, [dispatch]);
  const onBackClick = useCallback(() => dispatch(goBack()), [dispatch]);

  useEffect(() => {
    if (countryCode !== 'FI' && products.length === 0) {
      dispatch(loadProductsForCountry(countryCode, false));
    }
  }, [countryCode, products.length, dispatch]);

  const preventDoubleClick = useRef(false);
  const askCount = askPackageCount(selectedProduct, buySeries);
  const onNextClick = useCallback(() => {
    if (askCount && !numberOfPackages) {
      return;
    }
    if (preventDoubleClick.current) {
      return;
    }
    preventDoubleClick.current = true;
    try {
      addMailboxDeliveryIfEligible(selectedProduct);
      addFreeDeliveryIfEligible(selectedProduct);
      dispatch(goNext());
    } finally {
      preventDoubleClick.current = false;
    }
  }, [dispatch, askCount, numberOfPackages, selectedProduct]);

  const addFreeDeliveryIfEligible = product => {
    // for big Baltic parcels that do not fit into automats
    const service = product?.services?.find(s => s.serviceCode === ServiceCode.Delivery8_16);
    if (['LV', 'LT'].includes(product?.countryCode) && product?.deliveryIncluded && product?.isLarge && service) {
      const hasDeliveryService = selectedServices?.some(s => s.serviceCode === ServiceCode.Delivery8_16);
      if (!hasDeliveryService) {
        dispatch(toggleService(service));
      }
    }
  };
  const addMailboxDeliveryIfEligible = product => {
    if (product?.mailboxDelivery) {
      const service = product?.services?.find(s => s.serviceCode === ServiceCode.DeliveryToMailBox);
      const hasJPLService = selectedServices?.some(s => s.serviceCode === ServiceCode.DeliveryToMailBox);
      if (!hasJPLService) {
        dispatch(toggleService(service));
      }
    }
  };
  const selectSize = useCallback(event => dispatch(selectLarge({ isLarge: !!event.target.checked })), [dispatch]);
  const productsLoaded = areProductsLoaded();

  const continueDisabled =
    !selectedProduct.id ||
    (selectedProduct.sizeCode === SizeCode.REFILL && !repackCode) ||
    (askCount && !numberOfPackages);

  const deliveryIncludedAbroad = sendAbroad && selectedProduct?.deliveryIncluded;
  const pickupAndDeliveryIncluded = !sendAbroad && selectedProduct?.deliveryIncluded;

  const maxSize = getMaxSize(selectedProduct);
  const ppServiceAvailable = selectedProduct?.services?.find(s => s?.serviceCode === 'PP');
  const noAutomats =
    selectedProduct?.isLarge ||
    selectedProduct?.weightClass > 35 ||
    selectedProduct?.isExtraLarge ||
    selectedProduct?.sizeCode === 'REPACK';

  const isExpress = parcelType === ParcelType.Express;
  const weight = isExpress
    ? +selectedProduct?.weightClass < 20
      ? +selectedProduct?.weightClass
      : 20
    : selectedProduct?.mailboxDelivery
    ? 1
    : selectedProduct?.weightClass;

  const productName =
    selectedProduct?.localizedName?.[language] || selectedProduct?.localizedName?.fi || selectedProduct?.displayName;

  return (
    <FullHeightColumn>
      <Box>
        {alreadyInBasket || hideBackButton ? (
          <Button onClick={backToShoppingBasket} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backToBasket')}
          </Button>
        ) : (
          <Button onClick={onBackClick} variant="plain" sx={{ color: 'primary' }}>
            {translate('buyParcel.backButton')}
          </Button>
        )}
      </Box>

      <Styled.h1 sx={{ color: 'secondary', mt: 0 }}>{translate('buyParcel.product.title')}</Styled.h1>

      <Flex
        sx={{
          flex: 'auto',
          flexDirection: 'column',
          justifyContent: ['space-between', null, null, 'flex-start'],
        }}
      >
        {!sendAbroad && (
          <Box sx={{ mb: 3 }}>
            <Switch checked={isLarge} onChange={selectSize} size="large">
              {translate('buyParcel.product.sizeSwitch')}
            </Switch>
          </Box>
        )}

        <ProductList products={products} productsLoaded={productsLoaded} />

        {parcelType === ParcelType.Express && onlyExpressParcels && !alreadyInBasket && (
          <Box sx={{ mb: [4] }}>{translate('buyParcel.express.onlyExpress')}</Box>
        )}
        {productName ? (
          <Box sx={{ mb: 3 }}>
            <h2>{productName}</h2>
            <Flex
              sx={{
                maxWidth: 900,
                flex: 1,
                flexDirection: 'column',
                gap: 3,
                my: 3,
              }}
            >
              <Flex
                sx={{
                  flex: 1,
                  flexDirection: 'row',
                  bg: 'blueLighter',
                  borderRadius: [0, 2],
                  py: 1,
                  px: 4,
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Box sx={{ width: '30px', height: '30px', mt: 1 }}>
                  <img
                    height="100%"
                    width="100%"
                    src={imagePathForProduct(selectedProduct?.sizeCode || 'boxxs2')}
                    alt={selectedProduct.displayName}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  {selectedProduct?.sizeCode === 'XXL Flex' ? (
                    <p>
                      {translate('buyParcel.product.xxlFlexDescription', {
                        maxSideSum: selectedProduct?.maxSideSum,
                        maxDimension: selectedProduct?.maxDimension,
                        weightClass: selectedProduct?.weightClass,
                      })}
                    </p>
                  ) : (
                    <p>
                      {translate('buyParcel.sizeConfirmation.maxSize')} {maxSize.join(' x ')} cm.
                    </p>
                  )}
                </Box>
              </Flex>

              <Flex
                sx={{
                  flex: 1,
                  flexDirection: 'row',
                  bg: 'blueLighter',
                  borderRadius: [0, 2],
                  py: 1,
                  px: 4,
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Box sx={{ width: '30px', height: '30px', mt: 1 }}>
                  <img src={imagePathForProduct('weight')} alt={selectedProduct.displayName} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <p>
                    {translate(
                      !ppServiceAvailable || isExpress
                        ? 'buyParcel.sizeConfirmation.maxWeight'
                        : 'buyParcel.sizeConfirmation.maxWeightWithoutPp'
                    )}{' '}
                    {weight} kg.
                  </p>
                </Box>
              </Flex>
              <Flex
                sx={{
                  flex: 1,
                  flexDirection: 'row',
                  bg: 'blueLighter',
                  borderRadius: [0, 2],
                  py: 1,
                  px: 4,
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Box sx={{ width: '30px', height: '30px', mt: 1 }}>
                  <img src={imagePathForProduct('automat')} alt={selectedProduct.displayName} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  {deliveryIncludedAbroad ? (
                    <p>{translate('buyParcel.sizeConfirmation.deliveryIncluded')}</p>
                  ) : pickupAndDeliveryIncluded ? (
                    <p>
                      {translate('buyParcel.sizeConfirmation.pickupAndDeliveryIncluded')}
                      {!sendAbroad ? translate('buyParcel.sizeConfirmation.deliveryTime') : null}
                    </p>
                  ) : !onlyExpressParcels ? (
                    <p>
                      {translate(
                        isExpress
                          ? 'buyParcel.sizeConfirmation.leaveForDriver'
                          : noAutomats
                          ? 'buyParcel.sizeConfirmation.leaveAtSpNoAutomat'
                          : 'buyParcel.sizeConfirmation.leaveAtSp'
                      )}
                      {!sendAbroad && !isExpress ? translate('buyParcel.sizeConfirmation.deliveryTime') : null}
                    </p>
                  ) : null}
                </Box>
              </Flex>
            </Flex>
            {getExtraInfo(selectedProduct, translate, isExpress, onlyExpressParcels)}
            {askCount && <PackageCountQuestion />}
            {[SizeCode.REPACK, SizeCode.REFILL].includes(selectedProduct.sizeCode) && (
              <RePackSelection repackRefill={repackRefill} setRepackRefill={setRepackRefill} />
            )}
          </Box>
        ) : null}

        <Flex
          sx={{
            flexDirection: ['row-reverse', null, 'row'],
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ alignSelf: ['flex-end', null, 'flex-start'], flex: 'none' }}
            onClick={onNextClick}
            disabled={continueDisabled}
          >
            {translate('buyParcel.continue')}
          </Button>
        </Flex>
      </Flex>
    </FullHeightColumn>
  );
};

const getMaxSize = product => {
  if (product?.maxSize?.height && product?.maxSize?.width && product?.maxSize?.depth)
    return [product.maxSize.height, product.maxSize.width, product.maxSize.depth];
  else if (product?.sizeCode === 'Polkupyörä') {
    return [80, 230];
  } else return [0, 0, 0];
};

const getExtraInfo = (product, translate, isExpress, onlyExpressParcels) => {
  if (isExpress) return null;
  if (product?.sizeCode === 'XXS') {
    return (
      <p sx={{ mt: 3 }}>
        {translate('buyParcel.sizeConfirmation.xxsExtraCost')}{' '}
        {onlyExpressParcels ? '' : translate('buyParcel.sizeConfirmation.pickupAvailable')}
      </p>
    );
  } else if (product?.sizeCode === 'Suksipussi') {
    return <p sx={{ mt: 3 }}>{translate('buyParcel.product.skiDescription')}</p>;
  } else if (product?.sizeCode === 'Taulu') {
    return <p sx={{ mt: 3 }}>{translate('buyParcel.product.boardDescription', { weight: product.weightClass })}</p>;
  } else if (product?.sizeCode === 'Polkupyörä') {
    return (
      <>
        <p sx={{ mt: 3 }}>
          {translate('buyParcel.product.bikeDescription1', {
            weight: product.weightClass,
          })}
        </p>
        <p sx={{ mt: 3 }}>{translate('buyParcel.product.bikeDescription2')}</p>
      </>
    );
  } else if (product?.sizeCode === 'XXL Flex') {
    return (
      <>
        <Accordion
          variant="faq"
          title={translate('buyParcel.sizeConfirmation.whatSize')}
          sx={{
            maxWidth: 900,
            bg: 'white',
            boxShadow: 'dropdown',
            borderRadius: '8px',
            px: 3,
            py: 2,
            outline: 0,
          }}
          chevronColor="black"
          disableOnKeyDown={true}
        >
          <SizeChecker {...product} />
        </Accordion>
        <Box
          sx={{
            mt: 4,
            fontSize: 2,
            fontWeight: 'medium',
          }}
        >
          {translate('buyParcel.sizeConfirmation.areYouSendingBike')}
        </Box>
        <Box
          sx={{
            fontSize: 2,
            mt: 3,
            mb: 2,
          }}
        >
          {translate('buyParcel.sizeConfirmation.bikeInfo')}
        </Box>
      </>
    );
  } else if (!product?.deliveryIncluded) {
    return <p sx={{ mt: 3 }}>{translate('buyParcel.sizeConfirmation.pickupAvailable')}</p>;
  } else return null;
};
