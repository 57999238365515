/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Link from '../../components/Link';
import { Button } from '..';
import Widget from './Widget';

export default props => {
  const translate = getTranslate(useSelector(state => state.localize));

  const titleColor = props?.frontPage ? 'primary' : undefined;
  const bgColor = props?.frontPage ? '#F9B8CB' : undefined;
  const color = props?.frontPage ? 'black' : undefined;
  return (
    <Widget
      title={translate('sendParcelWidget.title')}
      titleColor={titleColor || 'pinkDark'}
      bg={bgColor}
      color={color}
      {...props}
    >
      <Box sx={{ fontSize: [2, 18] }}>{translate('sendParcelWidget.description')}</Box>
      <Box sx={{ mt: 3 }}>
        <Button
          variant="secondary"
          as={Link}
          to="/osta-paketti"
          sx={{
            bg: props?.frontPage ? 'newBlue' : 'undefined',
            color: props?.frontPage ? 'white' : 'undefined',
            border: props?.frontPage ? 0 : undefined,
          }}
        >
          {translate('sendParcelWidget.buyAndSendParcel')}
        </Button>
      </Box>
    </Widget>
  );
};
